<template>
  <div
    class="popup_wrap unno_pop"
    style="width:400px;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      type="button"
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020G070.156') }}</h1>
      <div class="content_box mt10">
        <span class="ml10">
          <input type="radio" id="trad1" name="trad" v-model="radioval" value="D" checked="checked" />
          <label for="trad1" class="mr50"><span></span>{{ $t('cp.CTRL020G070.157') }}</label><br />
          <input type="radio" id="trad2" name="trad" v-model="radioval" value="I" />
          <label for="trad2" class="mr10"><span></span>{{ $t('cp.CTRL020G070.158') }}</label>
        </span>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="applyValid()">{{ $t('cp.COMMON.014') }}</a>
        <a class="button blue lg" href="#" @click.prevent="$emit('close')">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TargetFactorPop',
  data () {
    return {
      radioval: 'D'
    }
  },
  mounted: function () {
    // console.log('mounted')
  },
  methods: {
    applyValid () {
      this.$emit('selectFunc', { target: 'TargetFactorPop', val: this.radioval })
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
