var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap unno_pop", staticStyle: { width: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.156"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("span", { staticClass: "ml10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.radioval,
                  expression: "radioval",
                },
              ],
              attrs: {
                type: "radio",
                id: "trad1",
                name: "trad",
                value: "D",
                checked: "checked",
              },
              domProps: { checked: _vm._q(_vm.radioval, "D") },
              on: {
                change: function ($event) {
                  _vm.radioval = "D"
                },
              },
            }),
            _c("label", { staticClass: "mr50", attrs: { for: "trad1" } }, [
              _c("span"),
              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.157"))),
            ]),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.radioval,
                  expression: "radioval",
                },
              ],
              attrs: { type: "radio", id: "trad2", name: "trad", value: "I" },
              domProps: { checked: _vm._q(_vm.radioval, "I") },
              on: {
                change: function ($event) {
                  _vm.radioval = "I"
                },
              },
            }),
            _c("label", { staticClass: "mr10", attrs: { for: "trad2" } }, [
              _c("span"),
              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.158"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.applyValid()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.014")))]
          ),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.007")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }